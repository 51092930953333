import { inject, Injectable } from '@angular/core';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, repeat, Subject, tap } from 'rxjs';

import { AuthService } from './auth.service';
import {
  BaseUser,
  EmailSetting,
  Filter,
  FtpSetting,
  GlobalOptOutSetting,
  NewEmailSetting,
  NewFilter,
  NewFtpSetting,
  NewNotificationSetting,
  NewOriginDestinationPair,
  NewScheduleSetting,
  NewUserRegistration,
  NotificationSetting,
  NotificationType,
  OriginDestinationPair,
  OxyBargeReport,
  ScheduleSetting,
  SmsPhoneVerification,
  User,
  UserRegistration,
} from '../models/user.model';
import { PreferenceParams, Preferences } from '../models/preferences.model';
import { SystemReport } from '../models/reportsLists.model';

@Injectable({ providedIn: 'root' })
export class UserService {
  private http = inject(HttpClient);
  private authService = inject(AuthService);

  private repeatFetchFilters = new Subject<void>();
  private repeatFetchCurrentUser = new Subject<void>();
  private repeatFetchOriginDestinations = new Subject<void>();
  private repeatFetchEmailSettings = new Subject<void>();
  private repeatFetchFtpSettings = new Subject<void>();
  private repeatFetchScheduleSettings = new Subject<void>();
  private repeatFetchScheduleTimes = new Subject<void>();
  private repeatFetchNotificationSettings = new Subject<void>();
  private repeatFetchPhoneNumber = new Subject<void>();
  private repeatGlobalOptOutSettings = new Subject<void>();

  private readonly sharedCurrentUser$: Observable<User>;
  private readonly sharedSelectedFilter$: Observable<Filter[]>;
  private readonly sharedOriginDestinations$: Observable<
    OriginDestinationPair[]
  >;
  private readonly sharedEmailSettings$: Observable<EmailSetting[]>;
  private readonly sharedFtpSettings$: Observable<FtpSetting[]>;
  private readonly sharedScheduleSettings$: Observable<ScheduleSetting[]>;
  private readonly sharedScheduleTimes$: Observable<string>;
  private readonly sharedNotificationSettings$: Observable<
    NotificationSetting[]
  >;
  private readonly sharedPhoneNumber$: Observable<string>;
  private readonly sharedGlobalOptOutSettings$: Observable<GlobalOptOutSetting>;

  constructor() {
    this.sharedCurrentUser$ = this.fetchCurrentUser().pipe(shareReplay(1));
    this.sharedSelectedFilter$ = this.fetchFilters().pipe(shareReplay(1));
    this.sharedOriginDestinations$ = this.fetchOriginDestinations().pipe(
      shareReplay(1),
    );
    this.sharedEmailSettings$ = this.fetchEmailSettings().pipe(shareReplay(1));
    this.sharedFtpSettings$ = this.fetchFtpSettings().pipe(shareReplay(1));
    this.sharedScheduleTimes$ = this.fetchScheduleTimes().pipe(shareReplay(1));
    this.sharedScheduleSettings$ = this.fetchScheduleSettings().pipe(
      shareReplay(1),
    );
    this.sharedNotificationSettings$ = this.fetchNotificationSettings().pipe(
      shareReplay(1),
    );
    this.sharedPhoneNumber$ = this.fetchPhoneNumber().pipe(shareReplay(1));
    this.sharedGlobalOptOutSettings$ = this.fetchGlobalOptOutSettings().pipe(
      shareReplay(1),
    );
  }

  getSelectedFilter() {
    return this.sharedSelectedFilter$.pipe(
      map(
        (filters) =>
          filters.find((filter) => filter.DEFAULT_FILTER_FLG === 'Y') ??
          filters[0],
      ),
    );
  }

  getFilters() {
    return this.sharedSelectedFilter$;
  }

  getCurrentUser() {
    return this.sharedCurrentUser$;
  }

  getOriginDestinations() {
    return this.sharedOriginDestinations$;
  }

  getEmailSettings() {
    return this.sharedEmailSettings$;
  }

  getFtpSettings() {
    return this.sharedFtpSettings$;
  }

  getScheduleSettings() {
    return this.sharedScheduleSettings$;
  }

  getScheduleTimes() {
    return this.sharedScheduleTimes$;
  }

  getExclusiveReports() {
    return this.authService.getCurrentUsername().pipe(
      switchMap((username) =>
        this.http.get<SystemReport[]>(`/api/TowlineExclusiveReportUserSetup`, {
          params: new HttpParams()
            .set('username', username)
            .set('forDropdown', 'True'),
        }),
      ),
    );
  }

  getReports(reportType: string) {
    return this.authService.getCurrentUsername().pipe(
      switchMap((username) =>
        this.http.get<SystemReport[]>(`/api/TowlineReport`, {
          params: new HttpParams()
            .set('username', username)
            .set('reportType', reportType),
        }),
      ),
    );
  }

  getNotificationSettings() {
    return this.sharedNotificationSettings$;
  }

  getNotificationTypes() {
    return this.http.get<NotificationType[]>('/api/notifications/types');
  }

  getGlobalOptOutSettings() {
    return this.sharedGlobalOptOutSettings$;
  }

  fetchGlobalOptOutSettings() {
    return this.authService
      .getCurrentUsername()
      .pipe(
        switchMap((username) =>
          this.http
            .get<GlobalOptOutSetting>(
              `/api/notifications/GlobalOptOut/${username}`,
            )
            .pipe(repeat({ delay: () => this.repeatGlobalOptOutSettings })),
        ),
      );
  }

  getBargeReportsForDashboard() {
    return this.http.get<OxyBargeReport[]>('/api/BargeReports/GetDashboard');
  }

  getBargeReportsAccess() {
    return this.authService
      .getCurrentUsername()
      .pipe(
        switchMap((username) =>
          this.http.get<boolean>(`/api/BargeReports/HasAccess/${username}`),
        ),
      );
  }

  getPhoneSmsVerification(phoneNumber: string) {
    return this.http.get<SmsPhoneVerification>(
      `/sms/phone/verify/${phoneNumber}`,
    );
  }

  fetchCurrentUser() {
    return this.authService.getCurrentUsername().pipe(
      switchMap((username) =>
        this.http.get<User[]>(`/api/TowlineUser/${username}`).pipe(
          map(([user]) => user),
          repeat({ delay: () => this.repeatFetchCurrentUser }),
        ),
      ),
    );
  }

  getUsers() {
    return this.http.get<BaseUser[]>('/api/TowlineUser');
  }

  getAllUsers() {
    return this.http.get<BaseUser[]>('/api/TowlineUser?allUsers=1');
  }

  sendUserRegistration(userRegistration: NewUserRegistration) {
    return this.http.post<UserRegistration>(
      `/api/TowlineRequestAccount`,
      userRegistration,
    );
  }

  fetchFilters() {
    return this.authService.getCurrentUsername().pipe(
      switchMap((username) =>
        this.http.get<Filter[]>(`/api/TowlineFilters/${username}`).pipe(
          map((filters) =>
            filters.sort((a, b) => {
              if (a.FILTER_NAME < b.FILTER_NAME) {
                return -1;
              }
              if (a.FILTER_NAME > b.FILTER_NAME) {
                return 1;
              }
              return 0;
            }),
          ),
          repeat({ delay: () => this.repeatFetchFilters }),
        ),
      ),
    );
  }

  fetchOriginDestinations() {
    return this.authService
      .getCurrentUsername()
      .pipe(
        switchMap((username) =>
          this.http
            .get<
              OriginDestinationPair[]
            >(`/api/TowlineOriginsDestinations`, { params: new HttpParams().set('username', username) })
            .pipe(repeat({ delay: () => this.repeatFetchOriginDestinations })),
        ),
      );
  }

  fetchEmailSettings() {
    return this.authService.getCurrentUsername().pipe(
      switchMap((username) =>
        this.http
          .get<EmailSetting[]>(`/api/TowlineEmailLists`, {
            params: new HttpParams().set('username', username),
          })
          .pipe(repeat({ delay: () => this.repeatFetchEmailSettings })),
      ),
    );
  }

  fetchFtpSettings() {
    return this.authService.getCurrentUsername().pipe(
      switchMap((username) =>
        this.http
          .get<FtpSetting[]>(`/api/TowlineFTPHosts`, {
            params: new HttpParams().set('username', username),
          })
          .pipe(repeat({ delay: () => this.repeatFetchFtpSettings })),
      ),
    );
  }

  fetchNotificationSettings() {
    return this.authService
      .getCurrentUsername()
      .pipe(
        switchMap((username) =>
          this.http
            .get<NotificationSetting[]>(`/api/notifications/${username}`)
            .pipe(
              repeat({ delay: () => this.repeatFetchNotificationSettings }),
            ),
        ),
      );
  }

  getPhoneNumber() {
    return this.sharedPhoneNumber$;
  }

  fetchPhoneNumber() {
    return this.authService
      .getCurrentUsername()
      .pipe(
        switchMap((username) =>
          this.http
            .get<string>(`/api/notifications/phone/${username}`)
            .pipe(repeat({ delay: () => this.repeatFetchPhoneNumber })),
        ),
      );
  }

  fetchScheduleSettings() {
    return this.authService.getCurrentUsername().pipe(
      switchMap((username) =>
        this.http
          .get<ScheduleSetting[]>(`/api/TowlineSchedule/`, {
            params: new HttpParams().set('username', username),
          })
          .pipe(repeat({ delay: () => this.repeatFetchScheduleSettings })),
      ),
    );
  }

  // check
  fetchScheduleTimes() {
    return this.authService.getCurrentUsername().pipe(
      switchMap((username) =>
        this.http
          .get<string>(`/api/TowlineScheduleTimes/`, {
            params: new HttpParams().set('username', username),
          })
          .pipe(repeat({ delay: () => this.repeatFetchScheduleTimes })),
      ),
    );
  }

  saveFilter(filter: Filter) {
    if (filter.FILTERS_ID) {
      return this.updateFilter(filter);
    } else {
      return this.createFilter(filter);
    }
  }

  nextFilter() {
    this.repeatFetchFilters.next();
  }

  createFilter(filter: Filter | NewFilter) {
    return this.http
      .post<Filter>(`/api/TowlineFilters`, filter)
      .pipe(tap(() => this.repeatFetchFilters.next()));
  }

  updateFilter(filter: Filter) {
    return this.http
      .put<Filter>(`/api/TowlineFilters/${filter.FILTERS_ID}`, filter)
      .pipe(tap(() => this.repeatFetchFilters.next()));
  }

  deleteFilter(filterId: number) {
    return this.http
      .delete<Filter>(`/api/TowlineFilters/${filterId}`)
      .pipe(tap(() => this.repeatFetchFilters.next()));
  }

  refreshCurrentUser() {
    this.repeatFetchCurrentUser.next();
  }

  getPreferences(params: PreferenceParams) {
    return this.http.get<Preferences>('/api/AppUserPreference', { params });
  }

  updatePreferences(preferences: Preferences) {
    return this.http.put(
      `/api/AppUserPreference/${preferences.PREFERENCE_ID}`,
      preferences,
    );
  }

  createOriginDestination(
    odPair: OriginDestinationPair | NewOriginDestinationPair,
  ) {
    return this.http
      .post<OriginDestinationPair>(`/api/TowlineOriginsDestinations`, odPair)
      .pipe(tap(() => this.repeatFetchOriginDestinations.next()));
  }

  updateOriginDestination(odPair: OriginDestinationPair) {
    return this.http
      .put<OriginDestinationPair>(
        `/api/TowlineOriginsDestinations/${odPair.CUSTOM_ORIGINS_ID}`,
        odPair,
      )
      .pipe(tap(() => this.repeatFetchOriginDestinations.next()));
  }

  deleteOriginDestination(odPairId: number) {
    return this.http
      .delete<OriginDestinationPair>(
        `/api/TowlineOriginsDestinations/${odPairId}`,
      )
      .pipe(tap(() => this.repeatFetchOriginDestinations.next()));
  }

  createEmailSetting(emailSetting: EmailSetting | NewEmailSetting) {
    return this.http
      .post<EmailSetting>(`/api/TowlineEmailLists`, emailSetting)
      .pipe(tap(() => this.repeatFetchEmailSettings.next()));
  }

  updateEmailSetting(emailSetting: EmailSetting) {
    return this.http
      .put(
        `/api/TowlineEmailLists/${emailSetting.EMAIL_LISTS_ID}`,
        emailSetting,
      )
      .pipe(tap(() => this.repeatFetchEmailSettings.next()));
  }

  deleteEmailSetting(emailSettingId: number) {
    return this.http
      .delete<EmailSetting>(`/api/TowlineEmailLists/${emailSettingId}`)
      .pipe(tap(() => this.repeatFetchEmailSettings.next()));
  }

  createFtpSetting(ftpSetting: FtpSetting | NewFtpSetting) {
    return this.http
      .post<FtpSetting>(`/api/TowlineFTPHosts`, ftpSetting)
      .pipe(tap(() => this.repeatFetchFtpSettings.next()));
  }

  updateFtpSetting(ftpSetting: FtpSetting) {
    return this.http
      .put(`/api/TowlineFTPHosts/${ftpSetting.FTP_HOSTS_ID}`, ftpSetting)
      .pipe(tap(() => this.repeatFetchFtpSettings.next()));
  }

  deleteFtpSetting(ftpSettingId: number) {
    return this.http
      .delete<FtpSetting>(`/api/TowlineFTPHosts/${ftpSettingId}`)
      .pipe(tap(() => this.repeatFetchFtpSettings.next()));
  }

  createNotificationSetting(
    notificationSetting: NotificationSetting | NewNotificationSetting,
  ) {
    const username = this.authService.getUsername();
    return this.http
      .post<NotificationSetting>(
        `/api/notifications/${username}`,
        notificationSetting,
      )
      .pipe(tap(() => this.repeatFetchNotificationSettings.next()));
  }

  updateNotificationSetting(notificationSetting: NotificationSetting) {
    const username = this.authService.getUsername();
    return this.http
      .put<NotificationSetting>(
        `/api/notifications/${username}/${notificationSetting.NotificationCriteriaId}`,
        notificationSetting,
      )
      .pipe(tap(() => this.repeatFetchNotificationSettings.next()));
  }

  deleteNotificationSetting(notificationSettingId: string) {
    const username = this.authService.getUsername();
    return this.http
      .delete<NotificationSetting>(
        `/api/notifications/${username}/${notificationSettingId}`,
      )
      .pipe(tap(() => this.repeatFetchNotificationSettings.next()));
  }

  createScheduleSetting(schedule: ScheduleSetting | NewScheduleSetting) {
    return this.http
      .post<ScheduleSetting>(`/api/TowlineSchedule`, schedule)
      .pipe(tap(() => this.repeatFetchScheduleSettings.next()));
  }

  updateScheduleSetting(schedule: ScheduleSetting) {
    return this.http
      .put<ScheduleSetting>(`/api/TowlineSchedule/${1}`, schedule)
      .pipe(tap(() => this.repeatFetchScheduleSettings.next()));
  }

  deleteScheduleSetting(schedule: number) {
    return this.http
      .delete<ScheduleSetting>(`/api/TowlineSchedule/${schedule}`)
      .pipe(tap(() => this.repeatFetchScheduleSettings.next()));
  }

  updateGlobalOptOut(optOutConfig: GlobalOptOutSetting) {
    return this.authService
      .getCurrentUsername()
      .pipe(
        switchMap((username) =>
          this.http
            .post<GlobalOptOutSetting>(
              `/api/notifications/GlobalOptOut/${username}`,
              optOutConfig,
            )
            .pipe(tap(() => this.repeatGlobalOptOutSettings.next())),
        ),
      );
  }

  updatePhoneNumber(mobileNumber: string | null) {
    const headers = {
      'Content-Type': 'application/json; charset=UTF-8',
    };

    return this.authService
      .getCurrentUsername()
      .pipe(
        switchMap((username) =>
          this.http
            .post<
              string | null
            >(`/api/notifications/phone/${username}`, mobileNumber, { headers })
            .pipe(tap(() => this.repeatFetchPhoneNumber.next())),
        ),
      );
  }

  requestAccount(data: object) {
    return this.http.post('/api/TowlineRequestAccount', data);
  }
}
